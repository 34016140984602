import React, { Component } from 'react';
import {
    Button,
    Card,
    CardBody,
    CardSubtitle,
    CardText,
    CardTitle,
    FormGroup,
    Input,
    Label
} from 'reactstrap';

class AddNewReport extends Component {

    emptyItem = {
        message: '',
        userId: JSON.parse(localStorage.getItem('user')).id
    }

    constructor(props) {
        super(props);
        this.state = {
            item: this.emptyItem
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        let item = {...this.state.item};
        item[name] = value;
        this.setState({item});
    }

    async sendNewReport() {
        await fetch('https://fms-api.kepczynski.pl/api/v1/rep/', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('user')).accessToken
            },
            body: JSON.stringify(this.state.item)
        });
        window.location.reload(false);
    }

    async handleSubmit(event) {
        event.preventDefault();
        await this.sendNewReport();
        window.location.reload(false);
    }

    render() {
        const title = <h2>Zgłoś problem</h2>;

        return <div>
            <div>
                <hr />
                <div>
                    <Card>
                        <CardBody>
                            <CardTitle tag="h2">{title}</CardTitle>
                            <CardSubtitle tag="h6" className="my-4 text-muted">Wypełnij poniższy formularz, aby zgłosić problem z samochodem</CardSubtitle>
                            <hr className="my-3"/>
                            <CardText>
                                <form className={"my-2"} onSubmit={this.handleSubmit}>
                                    <FormGroup>
                                        <Label for="username">Nazwa użytkownika</Label>
                                        <Input type="text" disabled={true} name="username" id="username"
                                               value={JSON.parse(localStorage.getItem('user')).username || ''} />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="message">Wiadomość</Label>
                                        <Input
                                            type="textarea"
                                            name="message"
                                            value={this.state.item.message}
                                            onChange={this.handleChange}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Button color="warning" type="submit">Wyślij</Button>
                                    </FormGroup>
                                </form>
                            </CardText>
                        </CardBody>
                    </Card>
                </div>
            </div>
        </div>
    }
}

export default AddNewReport;