import React, { Component } from 'react';
import { Button, Table,
    Card,
    CardBody,
    CardSubtitle,
    CardText,
    CardTitle } from 'reactstrap';
import axios from "axios";
import authHeader from '../services/auth-header';
import Scheduler from '../reservations/Scheduler';

class UsersCarList extends Component {

    lastURLSegment = window.location.href.substr(window.location.href.lastIndexOf('/') + 1);

    constructor(props) {
        super(props);
        this.state = {
            cars: [],
            selectedCarName: '',
            selectedCarId: '',
            isLoading: true,
            showHideResvs: false,
        };
        this.release = this.release.bind(this);
        this.showHideResvs = this.showHideResvs.bind(this);
    }

    showHideResvs(carId, brand, model) {
        this.setState(state => ({
            showHideResvs: !state.showHideResvs,
            selectedCarName: brand + " " + model,
            selectedCarId: carId
        }));
    }

    componentDidMount() {
        this.setState({isLoading: true});

        axios.get('https://fms-api.kepczynski.pl/api/v1/car/' + this.props.id + '/cars', { headers: authHeader() })
            .then(response => this.setState({cars: response.data, isLoading: false}));
    }

    async release(carId) {
        axios.delete('https://fms-api.kepczynski.pl/api/v1/car/' + carId + '/release', { headers: authHeader() })
            .then(() => {
                window.location.reload(false);
            });
    }

    render() {
        const {cars, isLoading} = this.state;

        if (isLoading) {
            return <p className="p-5">Loading...</p>;
        }

        const carList = cars.map(car => {
            return <tr key={car.id}>
                <td className="align-middle">{car.licensePlate}</td>
                <td className="align-middle">{car.brand}</td>
                <td className="align-middle">{car.model}</td>
                <td className="align-middle">
                    <Button color="primary" onClick={() => this.showHideResvs(car.id, car.brand, car.model)}>Rezerwuj</Button>{' '}
                    <Button color="danger" onClick={() => { if (window.confirm('Czy na pewno chcesz usunąć ten samochód?')) this.release(car.id) } }>Usuń przypisanie</Button>
                </td>
            </tr>
        });
        return (
            <div>
                <hr />
                <div>
                    <Card>
                        <CardBody>
                            <CardTitle tag="h2">Lista samochodów</CardTitle>
                            <CardSubtitle tag="h6" className="my-4 text-muted">Lista samochodów użytkownika</CardSubtitle>
                            <hr className="my-3"/>
                            <CardText tag="div" className="mb-3">
                                <Table className="mt-4">
                                    <thead>
                                    <tr>
                                        <th width="25%">Numer rejestracyjny</th>
                                        <th width="20%">Marka</th>
                                        <th width="20%">Model</th>
                                        <th width="35%">Akcje</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {carList}
                                    </tbody>
                                </Table>
                                {this.state.showHideResvs &&
                                <Scheduler
                                    userId={(this.lastURLSegment === "") ? JSON.parse(localStorage.getItem('user')).id : this.lastURLSegment}
                                    carId={this.state.selectedCarId}
                                    carName={this.state.selectedCarName}
                                />}
                            </CardText>
                        </CardBody>
                    </Card>
                </div>
            </div>
        );
    }
}

export default UsersCarList;