import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Container, Form, FormGroup, Input, Label } from 'reactstrap';
import AppNavbar from '../AppNavbar';
import axios from "axios";
import authHeader from "../services/auth-header";

class CarEdit extends Component {

    emptyItem = {
        id: '',
        brand: '',
        model: '',
        licensePlate: '',
        fuelType: '',
        registrationReview: '',
        insurance: '',
        fuelLevel: '',
        tireChange: '',
        userId: ''
    };

    lastURLSegment = window.location.href.substr(window.location.href.lastIndexOf('/') + 1);

    constructor(props) {
        super(props);
        this.state = {
            item: this.emptyItem,
            brands: [],
            fuelTypeEnum: [],
            selectedBrand: "",
            users: [],
            selectedUser: ''
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeUser = this.handleChangeUser.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async componentDidMount() {
        if (this.lastURLSegment !== 'new') {
            axios.get('https://fms-api.kepczynski.pl/api/v1/car/' + this.props.match.params.id, { headers: authHeader() })
                .then(response => this.setState({item: response.data}));
        }
        console.log(this.props.match.params.id);
        axios.get('https://gist.githubusercontent.com/pcgeek86/78f4cad29dd16961ceeeee654127a0db/raw/b9a19ee5c6fb283eaf2f863a18ba9176359abd47/Car%2520Manufacturers.json')
            .then(response => {
                let brandsFromApi = response.data.map(brand => {
                    return { value: brand, display: brand };
                });
                this.setState({
                    brands: [
                        {
                            value: "",
                            display: "(Wybierz markę samochodu)"
                        }
                    ].concat(brandsFromApi)
                });
            });
        axios.get('https://fms-api.kepczynski.pl/api/auth/users')
            .then(response => {
                let usersFromApi = response.data.map(user => {
                    return { value: user.id, display: user.username };
                });
                this.setState({
                    users: [
                        {
                            value: "",
                            display: "(Wybierz nazwę użytkownika)"
                        }
                    ].concat(usersFromApi)
                });
            });
        this.setState({
           fuelTypeEnum: [
               {
                   value: "",
                   display: "(Wybierz rodzaj paliwa)"
               },
               {
                   value: "gasoline",
                   display: "benzyna"
               },
               {
                   value: "diesel",
                   display: "diesel"
               },
               {
                   value: "electric",
                   display: "elektryczny"
               }
           ]
        });
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        let item = {...this.state.item};
        item[name] = value;
        this.setState({item});
    }

    handleChangeUser(event) {
        const target = event.target;
        const value = target.value;
        this.setState({selectedUser: value});
        this.setState((state,props)=>{
            return {
                item: {
                    ...state.item,
                    userId: state.selectedUser
                }
            }
        })
    }

    handleSubmit(event) {
        event.preventDefault();
        const {item, selectedUser} = this.state;
        fetch((item.id) ? 'https://fms-api.kepczynski.pl/api/v1/car/' + item.id + '?brand=' + item.brand + '&model=' + item.model + '&licensePlate=' + item.licensePlate + '&fuelType=' + item.fuelType + '&registrationReview=' + item.registrationReview + '&insurance=' + item.insurance + '&fuelLevel=' + item.fuelLevel + '&tireChange=' + item.tireChange + '&userId=' + selectedUser : 'https://fms-api.kepczynski.pl/api/v1/car', {
            method: (item.id) ? 'PUT' : 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('user')).accessToken
            },
            body: JSON.stringify(item),
        });
        window.location = '/cars';
    }

    render() {
        const {item, brands, fuelTypeEnum, users} = this.state;
        const title = <h2>{item.id ? 'Edytuj samochód' : 'Dodaj samochód'}</h2>;

        return <div>
            <AppNavbar/>
            <Container>
                {title}
                <Form onSubmit={this.handleSubmit}>
                    <FormGroup>
                        <Label for="brand">Marka</Label>
                        <Input type="select" name="brand" id="brand" value={item.brand || ''}
                               onChange={this.handleChange}>
                            {brands.map(brand => (
                                <option
                                    key={brand.value}
                                    value={brand.value}
                                >
                                    {brand.display}
                                </option>
                            ))}
                        </Input>
                    </FormGroup>
                    <FormGroup>
                        <Label for="model">Model</Label>
                        <Input type="text" name="model" id="model" value={item.model || ''}
                               onChange={this.handleChange}/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="licensePlate">Numer rejestracyjny</Label>
                        <Input type="text" name="licensePlate" id="licensePlate" value={item.licensePlate || ''}
                               onChange={this.handleChange}/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="userId">Użytkownik samochodu</Label>
                        <Input type="select" name="userId" id="userId" value={this.state.selectedUser || ''}
                               onChange={this.handleChangeUser}>
                            {users.map(user => (
                                <option
                                    key={user.value}
                                    value={user.value}
                                >
                                    {user.display}
                                </option>
                            ))}
                        </Input>
                    </FormGroup>
                    <FormGroup>
                        <Label for="fuelType">Rodzaj paliwa</Label>
                        <Input type="select" name="fuelType" id="fuelType" value={item.fuelType || ''}
                               onChange={this.handleChange}>
                            {fuelTypeEnum.map(fuelType => (
                                <option
                                    key={fuelType.value}
                                    value={fuelType.value}
                                >
                                    {fuelType.display}
                                </option>
                            ))}
                        </Input>
                    </FormGroup>
                    <FormGroup>
                        <Label for="registrationReview">Przegląd rejestracyjny</Label>
                        <Input type="date" name="registrationReview" id="registrationReview" value={item.registrationReview || ''}
                               onChange={this.handleChange}/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="insurance">Ubezpieczenie</Label>
                        <Input type="date" name="insurance" id="insurance" value={item.insurance || ''}
                               onChange={this.handleChange}/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="fuelLevel">Poziom paliwa</Label>
                        <Input type="number" name="fuelLevel" id="fuelLevel" min="0" max="4" value={item.fuelLevel || ''}
                               onChange={this.handleChange}/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="tireChange">Zmiana opon</Label>
                        <Input type="date" name="tireChange" id="tireChange" value={item.tireChange || ''}
                               onChange={this.handleChange}/>
                    </FormGroup>
                    <FormGroup>
                        <Button color="primary" type="submit">Zapisz</Button>{' '}
                        <Button color="secondary" tag={Link} to={(item.id) ? '/cars/' + this.props.match.params.id : '/cars/'}>Anuluj</Button>
                    </FormGroup>
                </Form>
            </Container>
        </div>
    }
}

export default CarEdit;