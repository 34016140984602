import React, { Component } from 'react';
import './App.css';
import Home from './Home';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import CarList from "./cars/CarList";
import UserList from "./users/UserList";
import ReservationsList from "./reservations/ReservationsList";
import CarEdit from "./cars/CarEdit";
import UserEdit from "./users/UserEdit";
import CarDetails from "./cars/CarDetails";
import Login from "./Login";
import UserDetails from "./users/UserDetails";
import ReportList from "./reports/ReportList";

class App extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route path='/' exact={true} component={Home}/>
          <Route path='/login' exact={true} component={Login}/>
          <Route path='/cars' exact={true} component={CarList}/>
          <Route path='/reports' exact={true} component={ReportList}/>
          <Route path='/users' exact={true} component={UserList}/>
          <Route path='/resvs' exact={true} component={ReservationsList}/>
          <Route path='/resvs/:id' exact={true} component={ReservationsList}/>
          <Route path='/cars/edit/:id' component={CarEdit}/>
          <Route path='/cars/new' component={CarEdit}/>
          <Route path='/users/edit/:id' component={UserEdit}/>
          <Route path='/users/new' component={UserEdit}/>
          <Route path='/cars/:id' component={CarDetails}/>
          <Route path='/users/:id' component={UserDetails}/>
        </Switch>
      </Router>
    )
  }
}

export default App;