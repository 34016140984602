import React, { Component } from 'react';
import { Button, Container, Table } from 'reactstrap';
import AppNavbar from '../AppNavbar';
import axios from "axios";
import authHeader from '../services/auth-header';

class ReportList extends Component {

    constructor(props) {
        super(props);
        this.state = {reports: [], isLoading: true};
    }

    componentDidMount() {
        this.setState({isLoading: true});

        axios.get('https://fms-api.kepczynski.pl/api/v1/rep', { headers: authHeader() })
            .then(response => this.setState({reports: response.data, isLoading: false}));
    }

    setReady(id) {
        fetch(`https://fms-api.kepczynski.pl/api/v1/rep/${id}`, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(() => {
            window.location.reload(false);
        });
    }

    render() {
        const {reports, isLoading} = this.state;

        if (isLoading) {
            return <p className="p-5">Loading...</p>;
        }

        const reportList = reports.map(report => {
            return (
                <tr key={report.id}>
                    {(!report.ready) && <td className="align-middle">{report.user.userData.name + ' ' + report.user.userData.surname}</td>}
                    {(!report.ready) && <td className="align-middle">{report.user.email}</td>}
                    {(!report.ready) && <td className="align-middle">{report.message}</td>}
                    {(!report.ready) && <td className="align-middle">
                        <Button id="ready" color="primary" onClick={() => this.setReady(report.id)}>Gotowe</Button>
                    </td>}
                </tr>
            )
        });
        return (
            <div>
                <AppNavbar/>
                <Container>
                    <div className="float-right">
                        <Button id="refresh" color="secondary" onClick={() => window.location.reload(false)}>Odśwież</Button>
                    </div>
                    <h2 className={"my-5"}>Lista zgłoszeń</h2>
                    <Table className="mt-4">
                        <thead>
                        <tr>
                            <th width="15%">Imię i nazwisko</th>
                            <th width="15%">E-mail</th>
                            <th width="55%">Wiadomość</th>
                            <th width="15%">Akcje</th>
                        </tr>
                        </thead>
                        <tbody>
                        {reportList}
                        </tbody>
                    </Table>
                </Container>
            </div>
        );
    }
}

export default ReportList;