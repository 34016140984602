import React, { Component } from 'react';
import { Collapse, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import AuthService from "./services/auth.service";
import authHeader from './services/auth-header';
import axios from 'axios';

export default class AppNavbar extends Component {
    constructor(props) {
        super(props);
        this.state = {isOpen: false};
        this.toggle = this.toggle.bind(this);

        this.state = {
            showManagerScreen: false,
            showAdminScreen: false,
            currentUser: undefined,
            counter: ''
        };
    }

    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    componentDidMount() {
        const user = AuthService.getCurrentUser();

        if(user) {
            this.setState({
                currentUser: user,
                showManagerScreen: user.roles.includes("ROLE_MANAGER") || user.roles.includes("ROLE_ADMIN"),
                showAdminScreen: user.roles.includes("ROLE_ADMIN")
            });
        }

        if(user && (user.roles.includes("ROLE_MANAGER") || user.roles.includes("ROLE_ADMIN"))) {
            axios.get('https://fms-api.kepczynski.pl/api/v1/rep/ready', { headers: authHeader() })
                .then(response => this.setState({counter: response.data}));
        }
    }

    logOut() {
        AuthService.logout();
    }

    render() {
        const { currentUser, showManagerScreen, showAdminScreen, counter } = this.state;

        return <Navbar color="dark" dark expand="md">
            <div className='container' id='navbar'>
                <NavbarBrand tag={Link} to="/">FM System</NavbarBrand>
                <NavbarToggler onClick={this.toggle}/>
                <Collapse isOpen={this.state.isOpen} navbar>
                    <Nav navbar>
                        {showManagerScreen && (
                            <NavItem>
                                <NavLink href="/reports">Zgłoszenia {(counter > 0) && <span className="badge badge-danger">{counter}</span>}</NavLink>
                            </NavItem>
                        )}
                        {showManagerScreen && (
                            <NavItem>
                                <NavLink href="/cars">Samochody</NavLink>
                            </NavItem>
                            )}
                        {showAdminScreen && (
                            <NavItem>
                                <NavLink href="/users">Użytkownicy</NavLink>
                            </NavItem>
                        )}

                    </Nav>
                    {currentUser ? (
                        <div className="navbar-nav ml-auto">
                            <li className="nav-item">
                                <Link to={""} className="nav-link">{currentUser.username}</Link>
                            </li>
                            <li className="nav-item">
                                <Link to={"/login"} className="nav-link" onClick={this.logOut}>Wyloguj się</Link>
                            </li>
                        </div>
                    ) : (
                        <div className="navbar-nav ml-auto">
                            <li className="nav-item">
                                <Link to={"/login"} className="nav-link">Zaloguj się</Link>
                            </li>
                        </div>
                    )}
                </Collapse>
            </div>
        </Navbar>
    }
}