import React, {Component} from 'react';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';
import axios from "axios";
import authHeader from "../services/auth-header";

class QuickCarEdit extends Component {

    emptyItem = {
        id: '',
        registrationReview: '',
        insurance: '',
        fuelLevel: '',
        tireChange: ''
    }

    constructor(props) {
        super(props);
        this.state = {
            item: this.emptyItem,
            isLoading: true
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async componentDidMount() {
        axios.get('https://fms-api.kepczynski.pl/api/v1/car/' + this.props.id, { headers: authHeader() })
            .then(response => this.setState({item: response.data}));
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        let item = {...this.state.item};
        item[name] = value;
        this.setState({item});
    }

    async handleSubmit(event) {
        event.preventDefault();
        const {item} = this.state;
        await fetch('https://fms-api.kepczynski.pl/api/v1/car/' + item.id + '?registrationReview=' + item.registrationReview + '&insurance=' + item.insurance + '&fuelLevel=' + item.fuelLevel + '&tireChange=' + item.tireChange, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('user')).accessToken
            },
            body: JSON.stringify(item),
        });
        window.location.reload(false);
    }

    render() {
        const {item} = this.state;
        return (
            <div>
                <h2 className="mb-4">Szybka edycja</h2>
                <Form onSubmit={this.handleSubmit}>
                    <FormGroup>
                        <Label for="registrationReview">Przegląd rejestracyjny</Label>
                        <Input type="date" name="registrationReview" id="registrationReview" value={item.registrationReview}
                               onChange={this.handleChange}/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="insurance">Ubezpieczenie</Label>
                        <Input type="date" name="insurance" id="insurance" value={item.insurance}
                               onChange={this.handleChange}/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="fuelLevel">Poziom paliwa</Label>
                        <Input type="number" name="fuelLevel" id="fuelLevel" min="0" max="4" value={item.fuelLevel}
                               onChange={this.handleChange}/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="tireChange">Zmiana opon</Label>
                        <Input type="date" name="tireChange" id="tireChange" value={item.tireChange}
                               onChange={this.handleChange}/>
                    </FormGroup>
                    <FormGroup>
                        <Button color="primary" type="submit">Zapisz</Button>
                    </FormGroup>
                </Form>
            </div>
        );
    }
}

export default QuickCarEdit;