import React, { Component } from 'react';
import '../App.css';
import {
    Button,
    Card,
    CardBody,
    CardSubtitle,
    CardText,
    CardTitle
} from 'reactstrap';
import axios from "axios";
import AuthService from "../services/auth.service"

class NewMarkScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {questions: [], score: 0};

        this.onValueChange = this.onValueChange.bind(this);
        this.formSubmit = this.formSubmit.bind(this);
    }

    componentDidMount() {
        this.setState({
            questions: [
                {
                    id: 1,
                    questionText: "Stan czystości na zewnątrz"
                },
                {
                    id: 2,
                    questionText: "Widoczne uszkodzenia blacharsko-lakiernicze"
                },
                {
                    id: 3,
                    questionText: "Stan czystości wewnątrz"
                },
                {
                    id: 4,
                    questionText: "Widoczne uszkodzenia wnętrza"
                }
            ]
        });
    }

    onValueChange(event) {
        this.setState({
            score: Number(this.state.score) + Number(event.target.value)
        });
    }

    async formSubmit(event) {
        event.preventDefault();
        this.setState({score: 0});
        await axios.post('https://fms-api.kepczynski.pl/api/v1/mark', {
            userId: AuthService.getCurrentUser().id,
            carId: this.props.carId,
            value: this.state.score
        });
        window.location.reload(true);
    }

    render() {
        const question = this.state.questions.map(question => {
            return (
                <div className={"my-4"}>
                    <div className={"form-text"}>
                        <h5>{question.questionText}</h5>
                    </div>
                    <div className="form-row">
                        <div className="col">
                            <label>
                                <input type="radio" value="1" onChange={this.onValueChange} />
                                {' '}1
                            </label>
                        </div>
                        <div className="col">
                            <label>
                                <input type="radio" value="2" onChange={this.onValueChange} />
                                {' '}2
                            </label>
                        </div>
                        <div className="col">
                            <label>
                                <input type="radio" value="3" onChange={this.onValueChange} />
                                {' '}3
                            </label>
                        </div>
                        <div className="col">
                            <label>
                                <input type="radio" value="4" onChange={this.onValueChange} />
                                {' '}4
                            </label>
                        </div>
                        <div className="col">
                            <label>
                                <input type="radio" value="5" onChange={this.onValueChange} />
                                {' '}5
                            </label>
                        </div>
                    </div>
                </div>
            )
        });

        return (
            <div>
                <hr />
                <div>
                    <Card>
                        <CardBody>
                            <CardTitle tag="h2">Stan samochodu</CardTitle>
                            <CardSubtitle tag="h6" className="my-4 text-muted">Wypełnij krótką ankietę dotyczącą stanu samochodu. Na podstawie Twojej opinii zarządca floty będzie zlecał prace związane z poprawą stanu samochodu.</CardSubtitle>
                            <hr className="my-3"/>
                            <CardText>
                                <form className={"my-2"} onSubmit={this.formSubmit}>
                                    {question}
                                    <Button color="success" type="submit">Zatwierdź</Button>
                                </form>
                            </CardText>
                        </CardBody>
                    </Card>
                </div>
            </div>
        );
    }
}

export default NewMarkScreen;