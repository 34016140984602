import React, { Component } from 'react';
import { Button, Container, Table, Row, Col } from 'reactstrap';
import AppNavbar from '../AppNavbar';
import axios from "axios";
import authHeader from '../services/auth-header';
import NewMarkScreen from '../marks/NewMarkScreen'
import AddNewReport from '../reports/AddNewReport'

class ReservationsList extends Component {

    constructor(props) {
        super(props);
        this.state = {reservations: [], isLoading: true, opened: false, showHideMarkScreen: false, showHideReport: false};
        this.open = this.open.bind(this);
        this.finish = this.finish.bind(this);
        this.showHideMarkScreen = this.showHideMarkScreen.bind(this);
        this.showHideReport = this.showHideReport.bind(this);
    }

    componentDidMount() {
        this.setState({isLoading: true});

        if (this.props.match.params.id) {
            axios.get('https://fms-api.kepczynski.pl/api/v1/resv/' + this.props.match.params.id, { headers: authHeader() })
                .then(response => this.setState({reservations: response.data, isLoading: false}));
        }
        else {
            axios.get('https://fms-api.kepczynski.pl/api/v1/resv', { headers: authHeader() })
                .then(response => this.setState({reservations: response.data, isLoading: false}));
        }
    }

    activeWindow(startDate, endDate, resv) {
        let open = startDate.getTime() - 900000;
        let close = endDate.getTime() + 900000;
        if (open <= Date.now() && Date.now() <= close && this.props.match.params.id && !resv) return true;
        return false;
    }

    async open(carId) {
        axios.put('https://fms-api.kepczynski.pl/api/v1/car/open/' + carId, { headers: authHeader() })
            .then(() => {
                this.componentDidMount();
            });
    }
    
    async finish(resvId, carId) {
        var fuelLevel = window.prompt("Podaj pozostałą ilość paliwa:");
        axios.put('https://fms-api.kepczynski.pl/api/v1/car/' + carId + '?fuelLevel=' + fuelLevel, { headers: authHeader() })
            .then(() => {
                this.componentDidMount();
            });
        axios.put('https://fms-api.kepczynski.pl/api/v1/resv/finish/' + resvId, { headers: authHeader() })
            .then(() => {
                this.componentDidMount();
            });
    }

    showHideMarkScreen(carId) {
        this.setState(state => ({
            showHideMarkScreen: !state.showHideMarkScreen,
            selectedCarId: carId
        }));
    }

    showHideReport() {
        this.setState(state => ({
            showHideReport: !state.showHideReport
        }));
    }

    render() {
        const {reservations, isLoading} = this.state;

        if (isLoading) {
            return <p className="p-5">Loading...</p>;
        }

        const reservationsList = reservations.map(reservation => {
            return <tr key={reservation.id}>
                <td className="align-middle">{reservation.user.userData.name + ' ' + reservation.user.userData.surname}</td>
                <td className="align-middle">{(reservation.car) ? reservation.car.brand + ' ' + reservation.car.model : 'Brak informacji'}</td>
                <td className="align-middle">
                    {new Intl.DateTimeFormat("pl-PL", {
                        year: "numeric",
                        month: "short",
                        day: "2-digit",
                        hour: "2-digit",
                        minute: "2-digit"
                    }).format(new Date(reservation.dos))}
                </td>
                <td className="align-middle">
                    {new Intl.DateTimeFormat("pl-PL", {
                        year: "numeric",
                        month: "short",
                        day: "2-digit",
                        hour: "2-digit",
                        minute: "2-digit"
                    }).format(new Date(reservation.doe))}
                </td>
                <td className="align-middle">
                    {(this.activeWindow(new Date(reservation.dos), new Date(reservation.doe), reservation.finished)) &&
                    <div>
                        <Row>
                            <Col>
                                <Button color="primary" block
                                        onClick={() => {
                                            this.open(reservation.car.id);
                                            this.setState({opened: !this.state.opened})
                                        }}>
                                    {(reservation.car.opened) ? "Zamknij" : "Otwórz"}
                                </Button>{' '}
                            </Col>
                            <Col>
                                <Button color="primary" block onClick={() => this.showHideMarkScreen(reservation.car.id)}>Oceń stan</Button>
                            </Col>
                        </Row>
                        <div className={"my-2"}/>
                        <Row>
                            <Col>
                                <Button color="primary" block onClick={this.showHideReport}>Zgłoś problem</Button>
                            </Col>
                            <Col>
                                <Button color="primary" block
                                        onClick={() => {
                                            this.finish(reservation.id, reservation.car.id);
                                        }}>
                                    {(reservation.finished) ? "Rozpocznij jazdę" : "Zakończ jazdę"}
                                </Button>
                            </Col>
                        </Row>
                    </div>
                    }
                </td>
            </tr>
        });
        return (
            <div>
                <AppNavbar/>
                <Container>
                    <div className="float-right">
                        <Button color="secondary" onClick={() => window.location.reload(false)}>Odśwież</Button>
                    </div>
                    <h2 className={"my-5"}>Lista rezerwacji</h2>
                    <Table className="mt-4">
                        <thead>
                        <tr>
                            <th width="17%">Imię i nazwisko</th>
                            <th width="17%">Samochód</th>
                            <th width="17%">Początek</th>
                            <th width="17%">Koniec</th>
                            <th width="32%">Akcje</th>
                        </tr>
                        </thead>
                        <tbody>
                        {reservationsList}
                        </tbody>
                    </Table>
                    {this.state.showHideMarkScreen &&
                    <div className={"mb-5"}>
                        <NewMarkScreen carId={this.state.selectedCarId}/>
                    </div>
                    }
                    {this.state.showHideReport &&
                    <div className={"mb-5"}>
                        <AddNewReport />
                    </div>
                    }
                </Container>
            </div>
        );
    }
}

export default ReservationsList;
