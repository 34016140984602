import React, { Component } from 'react';
import { Button, Container, Table } from 'reactstrap';
import AppNavbar from '../AppNavbar';
import { Link } from 'react-router-dom';
import axios from "axios";
import authHeader from '../services/auth-header';

class CarList extends Component {

    constructor(props) {
        super(props);
        this.state = {cars: [], isLoading: true};
        this.remove = this.remove.bind(this);
    }

    componentDidMount() {
        this.setState({isLoading: true});

        axios.get('https://fms-api.kepczynski.pl/api/v1/car', { headers: authHeader() })
            .then(response => this.setState({cars: response.data, isLoading: false}));
    }

    async remove(id) {
        await fetch(`https://fms-api.kepczynski.pl/api/v1/car/${id}`, {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(() => {
            let updatedCars = [...this.state.cars].filter(i => i.id !== id);
            this.setState({cars: updatedCars});
        });
    }

    render() {
        const {cars, isLoading} = this.state;

        if (isLoading) {
            return <p className="p-5">Loading...</p>;
        }

        const carList = cars.map(car => {
            return <tr key={car.id}>
                <td className="align-middle">{car.licensePlate}</td>
                <td className="align-middle">{car.brand}</td>
                <td className="align-middle">{car.model}</td>
                <td className="align-middle">{car.fuelLevel}</td>
                {new Date(car.registrationReview).getTime() < Date.now() ? <td className="align-middle text-danger">{car.registrationReview}</td> : <td className="align-middle text-success">{car.registrationReview}</td>}
                {new Date(car.insurance).getTime() < Date.now() ? <td className="align-middle text-danger">{car.insurance}</td> : <td className="align-middle text-success">{car.insurance}</td>}
                <td className="align-middle">
                    <Button color="primary" tag={Link} to={"/cars/" + car.id}>Szczegóły{' '}
                        {car.notificationsYellow > 0 && <span className="badge badge-warning">{car.notificationsYellow}</span>}{' '}
                        {car.notificationsRed > 0 && <span className="badge badge-danger">{car.notificationsRed}</span>}
                    </Button>
                </td>
            </tr>
        });
        return (
            <div>
                <AppNavbar/>
                <Container>
                    <div className="float-right">
                        <Button color="success" tag={Link} to="/cars/new">Dodaj samochód</Button>{' '}
                        <Button color="secondary" onClick={() => window.location.reload(false)}>Odśwież</Button>
                    </div>
                    <h2 className={"my-5"}>Lista samochodów</h2>
                    <Table className="mt-4">
                        <thead>
                        <tr>
                            <th width="15%">Numer rejestracyjny</th>
                            <th width="15%">Marka</th>
                            <th width="15%">Model</th>
                            <th width="12%">Poziom paliwa (0 - 4)</th>
                            <th width="15%">Przegląd</th>
                            <th width="12%">Ubezpieczenie</th>
                            <th width="16%">Akcje</th>
                        </tr>
                        </thead>
                        <tbody>
                        {carList}
                        </tbody>
                    </Table>
                </Container>
            </div>
        );
    }
}

export default CarList;