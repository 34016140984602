import React, { Component } from 'react';
import './App.css';
import AppNavbar from './AppNavbar';
import {Button, Container, Jumbotron} from 'reactstrap';
import {Link} from "react-router-dom";
import AuthService from "./services/auth.service";
import UserScreen from "./users/UserScreen";
import ManagerScreen from "./users/ManagerScreen";
import AdminScreen from "./users/AdminScreen";

class Home extends Component {
    constructor(props) {
        super(props);

        this.state = {
            role: undefined,
            currentUser: undefined
        };
    }

    componentDidMount() {
        const user = AuthService.getCurrentUser();

        if(user) {
            this.setState({
                role: user.roles[0],
                currentUser: user
            });
        }
    }

    render() {
        const { role } = this.state;

        return (
            <div>
                <AppNavbar/>
                <Container>
                    <Jumbotron>
                        {role === "ROLE_USER" && (
                            <UserScreen/>
                        )}
                        {role === "ROLE_MANAGER" && (
                            <ManagerScreen/>
                        )}
                        {role === "ROLE_ADMIN" && (
                            <AdminScreen/>
                        )}
                        {role === undefined && (
                            <div>
                                <h1 className="display-3">Witaj!</h1>
                                <p className="lead">Nie jesteś zalogowany!</p>
                                <hr className="my-2" />
                                <p>Skorzystaj z formularza logowania, aby skorzystać z serwisu.</p>
                                <p className="lead">
                                    <Button color="primary" tag={Link} to="/login/">Zaloguj się</Button>
                                </p>
                            </div>
                        )}
                    </Jumbotron>
                </Container>
            </div>
        );
    }
}

export default Home;