import React, { Component } from 'react';
import { Table,
    Card,
    CardBody,
    CardSubtitle,
    CardText,
    CardTitle } from 'reactstrap';
import axios from "axios";
import authHeader from '../services/auth-header';
import Scheduler from '../reservations/Scheduler';

class CarMarks extends Component {

    lastURLSegment = window.location.href.substr(window.location.href.lastIndexOf('/') + 1);

    constructor(props) {
        super(props);
        this.state = {
            marks: [],
            isLoading: true,
        };
    }

    componentDidMount() {
        this.setState({isLoading: true});

        axios.get('https://fms-api.kepczynski.pl/api/v1/mark/' + this.props.id, { headers: authHeader() })
            .then(response => this.setState({marks: response.data, isLoading: false}));
    }

    render() {
        const {marks, isLoading} = this.state;

        if (isLoading) {
            return <p className="p-5">Loading...</p>;
        }

        const markList = marks.map(mark => {
            return <tr key={mark.id}>
                <td className="align-middle">{mark.user.userData.name + ' ' + mark.user.userData.surname}</td>
                <td className="align-middle">{mark.user.email}</td>
                <td className="align-middle">{mark.value}</td>
            </tr>
        });
        return (
            <div>
                <hr />
                <div>
                    <Card>
                        <CardBody>
                            <CardTitle tag="h2">Lista ocen</CardTitle>
                            <CardSubtitle tag="h6" className="my-4 text-muted">Lista ocen stanu samochodu (0-20)</CardSubtitle>
                            <hr className="my-3"/>
                            <CardText>
                                <Table className="mt-4">
                                    <thead>
                                    <tr>
                                        <th width="40%">Imię i nazwisko</th>
                                        <th width="40%">Adres e-mail</th>
                                        <th width="20%">Ocena</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {markList}
                                    </tbody>
                                </Table>
                                {this.state.showHideResvs &&
                                <Scheduler
                                    userId={(this.lastURLSegment === "") ? JSON.parse(localStorage.getItem('user')).id : this.lastURLSegment}
                                    carId={this.state.selectedCarId}
                                    carName={this.state.selectedCarName}
                                />}
                            </CardText>
                        </CardBody>
                    </Card>
                </div>
            </div>
        );
    }
}

export default CarMarks;