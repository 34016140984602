import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
    Breadcrumb,
    BreadcrumbItem,
    Button,
    Card,
    CardBody,
    CardSubtitle, CardText,
    CardTitle,
    Container
} from 'reactstrap';
import AppNavbar from '../AppNavbar';
import axios from "axios";
import authHeader from "../services/auth-header";
import QuickCarEdit from "../cars/QuickCarEdit";
import CarMarks from "../marks/CarMarks";

class CarDetails extends Component {

    emptyItem = {
        id: '',
        brand: '',
        fuelLevel: '',
        fuelType: '',
        insurance: '',
        licensePlate: '',
        model: '',
        registrationReview: '',
        tireChange: ''
    };

    constructor(props) {
        super(props);
        this.state = {
            item: this.emptyItem,
            notifications: 0,
            showHideQEdit: false,
            showHideMarks: false,
            isLoading: true
        };
        this.remove = this.remove.bind(this);
        this.showHideQEdit = this.showHideQEdit.bind(this);
        this.showHideMarks = this.showHideMarks.bind(this);
        //this.handleChange = this.handleChange.bind(this);
        //this.handleSubmit = this.handleSubmit.bind(this);
    }

    async componentDidMount() {
        this.setState({isLoading: true});
        await axios.get('https://fms-api.kepczynski.pl/api/v1/car/' + this.props.match.params.id, { headers: authHeader() })
            .then(response => this.setState({item: response.data}));

        // await axios.get('https://fms-api.kepczynski.pl/api/v1/user', { headers: authHeader() })
        //     .then(response => console.log(response.data));

        await axios.get('https://fms-api.kepczynski.pl/api/v1/car/' + this.props.match.params.id + '/notificationsNumber', { headers: authHeader() })
            .then(response => this.setState({notifications: response.data, isLoading: false}));
    }

    async remove() {
        axios.delete('https://fms-api.kepczynski.pl/api/v1/car/' + this.props.match.params.id, { headers: authHeader() })
            .then(() => {
                this.props.history.push('/cars');
            });
    }

    showHideMarks() {
        this.setState(state => ({
            showHideMarks: !state.showHideMarks
        }));
    }

    showHideQEdit() {
        this.setState(state => ({
            showHideQEdit: !state.showHideQEdit
        }));
    }

    render() {
        const {item, isLoading} = this.state;
        const title = <h2>Szczegóły samochodu</h2>;

        const diffDays = (date, otherDate) => Math.ceil(Math.abs(date - otherDate) / (1000 * 60 * 60 * 24));

        if (isLoading) {
            return <p className="p-5">Loading...</p>;
        }

        return <div>
            <AppNavbar/>
            <Container>
                {title}
                <div>
                    <Breadcrumb>
                        <BreadcrumbItem><a href='/cars'>Lista samochodów</a></BreadcrumbItem>
                        <BreadcrumbItem active>Szczegóły samochodu</BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <div className={"my-5"}>
                    <Card>
                        <CardBody>
                            <CardTitle tag="h2">{item.brand + ' ' + item.model}{' '}
                                {item.notificationsYellow > 0 && <span className="badge badge-warning">{item.notificationsYellow}</span>}{' '}
                                {item.notificationsRed > 0 && <span className="badge badge-danger">{item.notificationsRed}</span>}
                            </CardTitle>
                            {/*Stan przeglądu, ubezpieczenia, poziomu paliwa, zmiany opon*/}
                            {new Date(item.registrationReview).getTime() < Date.now() &&
                                <CardSubtitle tag="h5" className="my-4 text-danger">Termin przeglądu rejestracyjnego upłynął {diffDays(new Date(item.registrationReview), Date.now()) - 1} dni temu</CardSubtitle>}
                            {diffDays(new Date(item.registrationReview), Date.now()) <= 15 && new Date(item.registrationReview).getTime() >= Date.now() &&
                                <CardSubtitle tag="h5" className="my-4 text-warning">Termin przeglądu rejestracyjnego upłynie za {diffDays(new Date(item.registrationReview), Date.now())} dni</CardSubtitle>}
                            {new Date(item.insurance).getTime() < Date.now() &&
                                <CardSubtitle tag="h5" className="my-4 text-danger">Termin ubezpieczenia upłynął {diffDays(new Date(item.insurance), Date.now()) - 1} dni temu</CardSubtitle>}
                            {diffDays(new Date(item.insurance), Date.now()) <= 15 && new Date(item.insurance).getTime() >= Date.now() &&
                            <CardSubtitle tag="h5" className="my-4 text-warning">Termin ubezpiecznia upłynie za {diffDays(new Date(item.insurance), Date.now())} dni</CardSubtitle>}
                            {item.fuelLevel <= 1 &&
                                <CardSubtitle tag="h5" className="my-4 text-danger">Pozostało {25 * item.fuelLevel}% paliwa</CardSubtitle>}
                            {item.fuelLevel === 2 &&
                            <CardSubtitle tag="h5" className="my-4 text-warning">Pozostało {25 * item.fuelLevel}% paliwa</CardSubtitle>}
                            {new Date(item.tireChange).getTime() < Date.now() &&
                                <CardSubtitle tag="h5" className="my-4 text-danger">Termin zmiany opon upłynął {diffDays(new Date(item.tireChange), Date.now()) - 1} dni temu</CardSubtitle>}
                            {diffDays(new Date(item.tireChange), Date.now()) <= 15 && new Date(item.tireChange).getTime() >= Date.now() &&
                            <CardSubtitle tag="h5" className="my-4 text-warning">Termin zmiany opon upłynie za {diffDays(new Date(item.tireChange), Date.now())} dni</CardSubtitle>}
                            <hr className="my-3"/>
                            <CardText tag="div" className="mb-3">
                                <ul className={"list-group"}>
                                    <li className={"list-group-item"}>{'Numer rejestracyjny: ' + item.licensePlate}</li>
                                    {item.fuelType === 'gasoline' && <li className={"list-group-item"}>Rodzaj paliwa: benzyna</li>}
                                    {item.fuelType === 'diesel' && <li className={"list-group-item"}>Rodzaj paliwa: diesel</li>}
                                    {item.fuelType === 'electric' && <li className={"list-group-item"}>Rodzaj paliwa: elektryczny</li>}
                                    <li className={"list-group-item"}>{'Ilość paliwa: ' + item.fuelLevel}</li>
                                    <li className={"list-group-item"}>{'Termin przeglądu rejestracyjnego: ' + item.registrationReview}</li>
                                    <li className={"list-group-item"}>{'Ważność ubezpieczenia: ' + item.insurance}</li>
                                    <li className={"list-group-item"}>{'Data zmiany opon: ' + item.tireChange}</li>
                                </ul>
                            </CardText>
                            <Button color="success" onClick={this.showHideMarks}>Oceny</Button>{' '}
                            <Button color="primary" onClick={this.showHideQEdit}>Szybka edycja</Button>{' '}
                            <Button color="warning" tag={Link} to={"/cars/edit/" + item.id}>Edytuj</Button>{' '}
                            <Button color="danger" onClick={() => { if (window.confirm('Czy na pewno chcesz usunąć ten samochód?')) this.remove(item.id) } }>Usuń</Button>
                            {this.state.showHideMarks &&
                            <div className={"my-5"}>
                                <CarMarks
                                    id={this.state.item.id}
                                />
                            </div>
                            }
                            {this.state.showHideQEdit &&
                                <div className={"my-5"}>
                                    <QuickCarEdit
                                        id={this.state.item.id}
                                        registrationReview={this.state.item.registrationReview}
                                        insurance={this.state.item.insurance}
                                        fuelLevel={this.state.item.fuelLevel}
                                        tireChange={this.state.item.tireChange}
                                    />
                                </div>
                            }
                        </CardBody>
                    </Card>
                </div>
            </Container>
        </div>
    }
}

export default CarDetails;