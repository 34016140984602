import React, { Component } from 'react';
import axios from 'axios';
import authHeader from '../services/auth-header';
import {
    Card,
    CardBody,
    CardSubtitle,
    CardText,
    CardTitle,
    FormGroup,
    Input,
    Label
} from 'reactstrap';

class ContactScreen extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedRole: '(Wybierz rolę)',
            isLoading: false,
            firstManager: [],
            firstAdmin: []
        };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.setState({selectedRole: event.target.value});
    }

    async componentDidMount() {
        this.setState({isLoading: true});
        await axios.get('https://fms-api.kepczynski.pl/api/auth/manager/', { headers: authHeader() })
            .then(response => this.setState({firstManager: response.data}));
        await axios.get('https://fms-api.kepczynski.pl/api/auth/admin/', { headers: authHeader() })
            .then(response => this.setState({firstAdmin: response.data, isLoading: false}));
    }

    render() {
        const title = <h2>Skontaktuj się z nami</h2>;
        const {firstManager, firstAdmin} = this.state;
        return <div>
            <div>
                <hr />
                <div>
                    <Card>
                        <CardBody>
                            <CardTitle tag="h2">{title}</CardTitle>
                            <CardSubtitle tag="h6" className="my-4 text-muted">Wybierz rolę w systemie, z którą chcesz się skontaktować</CardSubtitle>
                            <hr className="my-3"/>
                            <CardText>
                                <form className={"my-2"} onSubmit={this.handleSubmit}>
                                    <FormGroup>
                                        <Label for="role">Rola</Label>
                                        <Input type="select" name="role" id="role"
                                               value={this.state.selectedRole} onChange={this.handleChange}>
                                            <option disabled={true}>(Wybierz rolę)</option>
                                            <option>Zarządca floty</option>
                                            <option>Administrator</option>
                                        </Input>
                                    </FormGroup>
                                </form>
                                <hr/>
                                {(this.state.selectedRole === "Zarządca floty") &&
                                <div>
                                    <CardText>
                                        <ul className={"list-group"}>
                                            <li className={"list-group-item"}>{'Imię i nazwisko: ' + firstManager.userData.name + ' ' + firstManager.userData.surname}</li>
                                            <li className={"list-group-item"}>{'Nazwa użytkownika: ' + firstManager.username}</li>
                                            <li className={"list-group-item"}>{'E-mail: ' + firstManager.email}</li>
                                        </ul>
                                    </CardText>
                                </div>}
                                {(this.state.selectedRole === "Administrator") &&
                                <div>
                                    <CardText>
                                        <ul className={"list-group"}>
                                            <li className={"list-group-item"}>{'Imię i nazwisko: ' + firstAdmin.userData.name + ' ' + firstAdmin.userData.surname}</li>
                                            <li className={"list-group-item"}>{'Nazwa użytkownika: ' + firstAdmin.username}</li>
                                            <li className={"list-group-item"}>{'E-mail: ' + firstAdmin.email}</li>
                                        </ul>
                                    </CardText>
                                </div>}
                            </CardText>
                        </CardBody>
                    </Card>
                </div>
            </div>
        </div>
    }
}

export default ContactScreen;