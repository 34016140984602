import React, {Component} from 'react'
import Scheduler, {SchedulerData, ViewTypes} from 'react-big-scheduler'
import moment from 'moment'
import 'moment/locale/pl';
import withDragDropContext from '../reservations/withDnDContext'
import "react-big-scheduler/lib/css/style.css";
import axios from 'axios';
import authHeader from '../services/auth-header';

class OverlapCheck extends Component{

    emptyItem = {
        id: '',
        dos: '',
        doe: '',
        userId: '',
        carId: ''
    };

    resources = [
        {
            id: 'r0',
            name: this.props.carName
        }
    ];

    isNonWorkingTime = (schedulerData, time) => {
        const { localeMoment } = schedulerData;
        if (schedulerData.viewType === ViewTypes.Day) {
            let hour = localeMoment(time).hour();
            if (hour < 8 || hour > 18) return true;
        } else {
            let dayOfWeek = localeMoment(time).weekday();
            if (dayOfWeek === 5 || dayOfWeek === 6) return true;
        }

        return false;
    };

    constructor(props){
        super(props);
        this.state = {
            resvs: [],
            isLoading: true,
            newResvId: '',
            item: this.emptyItem,
        };

        moment.locale('pl');
        let schedulerData = new SchedulerData(Date.now(), ViewTypes.Day, false, false, {
            checkConflict: true,
            schedulerWidth: '53%',
            dayStartFrom: 7,
            dayStopTo: 19,
            resourceName: 'Samochód',
            nonAgendaDayCellHeaderFormat: 'HH:mm',
            nonAgendaOtherCellHeaderFormat: 'dddd|D.M',
            views: [
                {viewName: 'Dzień', viewType: ViewTypes.Day, showAgenda: false, isEventPerspective: false},
                {viewName: 'Tydzień', viewType: ViewTypes.Week, showAgenda: false, isEventPerspective: false}
            ],

            },
            {isNonWorkingTimeFunc: this.isNonWorkingTime});

        this.state = {
            viewModel: schedulerData
        }
    }

    componentDidMount() {
        this.setState({isLoading: true});
        axios.get('https://fms-api.kepczynski.pl/api/v1/resv/car/' + this.props.carId + "?userId=" + this.props.userId)
            .then(response => {
                let resvsFromApi = response.data.map(resv => {
                    return {
                        id: resv.id,
                        start: resv.dos,
                        end: resv.doe,
                        resourceId: 'r0',
                        title: 'Rezerwacja'
                    };
                });
                this.setState({
                    resvs: resvsFromApi
                });
                this.state.viewModel.setResources(this.resources);
                this.state.viewModel.setEvents(this.state.resvs);
                this.setState({
                    isLoading: false
                });
            });
    }

    render(){
        const {viewModel, isLoading} = this.state;
        if (isLoading) {
            return <p className="p-5">Loading...</p>;
        }
        return (
            <div>
                <div>
                    <Scheduler schedulerData={viewModel}
                               prevClick={this.prevClick}
                               nextClick={this.nextClick}
                               onSelectDate={this.onSelectDate}
                               onViewChange={this.onViewChange}
                               eventItemClick={this.eventClicked}
                               viewEventClick={this.ops1}
                               viewEventText="Usuń"
                               updateEventStart={this.updateEventStart}
                               updateEventEnd={this.updateEventEnd}
                               moveEvent={this.moveEvent}
                               newEvent={this.newEvent}
                               conflictOccurred={this.conflictOccurred}
                               toggleExpandFunc={this.toggleExpandFunc}
                    />
                </div>
            </div>
        )
    }

    prevClick = (schedulerData)=> {
        schedulerData.prev();
        schedulerData.setEvents(this.state.resvs);
        this.setState({
            viewModel: schedulerData
        })
    }

    nextClick = (schedulerData)=> {
        schedulerData.next();
        schedulerData.setEvents(this.state.resvs);
        this.setState({
            viewModel: schedulerData
        })
    }

    onViewChange = (schedulerData, view) => {
        schedulerData.setViewType(view.viewType, view.showAgenda, view.isEventPerspective);
        schedulerData.setEvents(this.state.resvs);
        this.setState({
            viewModel: schedulerData
        })
    }

    onSelectDate = (schedulerData, date) => {
        schedulerData.setDate(date);
        schedulerData.setEvents(this.state.resvs);
        this.setState({
            viewModel: schedulerData
        })
    }

    eventClicked = (schedulerData, event) => {
        // alert(`You just clicked an event: {id: ${event.id}, title: ${event.title}}`);
    };

    ops1 = (schedulerData, event) => {
        //alert(`You just executed ops1 to event: {id: ${event.id}, title: ${event.title}}`);
        if (window.confirm('Czy na pewno chcesz usunąć tę rezerwację?')) {
            axios.delete('https://fms-api.kepczynski.pl/api/v1/resv/' + event.id, { headers: authHeader() })
                .then(() => {
                    let updatedResvs = [...this.state.resvs].filter(i => i.id !== event.id);
                    this.setState({
                        resvs: updatedResvs,
                        viewModel: schedulerData
                    });
                    this.componentDidMount();
                });
        }
    };

    async addNewEventToDB() {
        return fetch('https://fms-api.kepczynski.pl/api/v1/resv', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('user')).accessToken
            },
            body: JSON.stringify(this.state.item),
        });
    }

    newEvent = async (schedulerData, slotId, slotName, start, end, type, item) => {
        if(window.confirm(`Czy na pewno chcesz dodać nową rezerwację?\nStart: ${start}, koniec: ${end}`)){

            let newFreshId = 0;
            schedulerData.events.forEach((item) => {
                if(item.id >= newFreshId)
                    newFreshId = item.id + 1;
            });
            this.setState({
                item: {
                    id: '',
                    dos: start.replace(" ", "T"),
                    doe: end.replace(" ", "T"),
                    userId: this.props.userId,
                    carId: this.props.carId
                }
            });

            let newEvent = {
                id: await this.addNewEventToDB().then(response => response.json()),
                title: 'Rezerwacja',
                start: start,
                end: end,
                resourceId: slotId
            };
            schedulerData.addEvent(newEvent);
            this.setState({
                viewModel: schedulerData
            });
        }
    }

    updateEventStart = (schedulerData, event, newStart) => {
        if(window.confirm(`Czy chcesz zmienić początek rezerwacji?\nNowy początek: ${newStart}`)) {
            schedulerData.updateEventStart(event, newStart);

            fetch('https://fms-api.kepczynski.pl/api/v1/resv/' + event.id + '?dos=' + newStart, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('user')).accessToken
                },
                body: JSON.stringify(this.state.item),
            }).then();
        }
        this.setState({
            viewModel: schedulerData
        });
    }

    updateEventEnd = (schedulerData, event, newEnd) => {
        if(window.confirm(`Czy chcesz zmienić koniec rezerwacji?\nNowy koniec: ${newEnd}}`)) {
            schedulerData.updateEventEnd(event, newEnd);

            fetch('https://fms-api.kepczynski.pl/api/v1/resv/' + event.id + '?doe=' + newEnd, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('user')).accessToken
                },
                body: JSON.stringify(this.state.item),
            }).then();
        }
        this.setState({
            viewModel: schedulerData
        })
    }

    moveEvent = (schedulerData, event, slotId, slotName, start, end) => {
        if(window.confirm(`Czy chcesz przenieść rezerwację?\nNowy początek: ${start}\nNowy koniec: ${end}`)) {
            schedulerData.moveEvent(event, slotId, slotName, start, end);
            fetch('https://fms-api.kepczynski.pl/api/v1/resv/' + event.id + '?dos=' + start + '&doe=' + end, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('user')).accessToken
                },
                body: JSON.stringify(this.state.item),
            }).then();
            this.setState({
                viewModel: schedulerData
            })
        }
    }

    conflictOccurred = (schedulerData, action, event, type, slotId, slotName, start, end) => {
        alert(`Pojedynczy samochód nie może być zarezerwowany wiele razy jednocześnie! Wybierz inny termin!`);
    }

    toggleExpandFunc = (schedulerData, slotId) => {
        schedulerData.toggleExpandStatus(slotId);
        this.setState({
            viewModel: schedulerData
        });
    }
}

export default withDragDropContext(OverlapCheck)
