import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {
    Button,
    Card,
    CardBody,
    CardSubtitle,
    CardText,
    CardTitle,
    FormGroup,
    Input,
    Label
} from 'reactstrap';

class PwdChgScreen extends Component {

    constructor(props) {
        super(props);
        this.state = {
            password: "",
            message: ""
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        this.setState({password: event.target.value, message: ""})
    }

    async changePassword(id, password) {
        await fetch(`https://fms-api.kepczynski.pl/api/auth/passwd/${id}?password=${password}`, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('user')).accessToken
            }
        });
        window.location.reload(false);
    }

    async handleSubmit(event) {
        event.preventDefault();
        if (this.state.password === "") {
            return (
                this.setState({message: "Hasło nie może być puste!"})
            );
        }
        else if (this.state.password.length < 6) {
            return (
                this.setState({message: "Hasło nie może być krótsze niż 6 znaków!"})
            );
        }
        await this.changePassword(JSON.parse(localStorage.getItem('user')).id, this.state.password)
        this.props.history.push('/');
    }

    render() {
        const title = <h2>Zmień hasło</h2>;

        return <div>
            <div>
                <hr />
                <div>
                    <Card>
                        <CardBody>
                            <CardTitle tag="h2">{title}</CardTitle>
                            <CardSubtitle tag="h6" className="my-4 text-muted">Wypełnij pole hasło, aby zmienić swoje hasło</CardSubtitle>
                            <hr className="my-3"/>
                            <CardText>
                                <form className={"my-2"} onSubmit={this.handleSubmit}>
                                    <FormGroup>
                                        <Label for="username">Nazwa użytkownika</Label>
                                        <Input type="text" disabled={true} name="username" id="username"
                                               value={JSON.parse(localStorage.getItem('user')).username || ''} />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="password">Hasło</Label>
                                        <Input
                                            type="password"
                                            className="form-control"
                                            name="password"
                                            value={this.state.password}
                                            onChange={this.handleChange}
                                        />
                                    </FormGroup>
                                    {this.state.message && (
                                        <div className="form-group">
                                            <div className="alert alert-danger" role="alert">
                                                {this.state.message}
                                            </div>
                                        </div>
                                    )}
                                    <FormGroup>
                                        <Button color="warning" type="submit">Zapisz</Button>
                                    </FormGroup>
                                </form>
                            </CardText>
                        </CardBody>
                    </Card>
                </div>
            </div>
        </div>
    }
}

export default withRouter(PwdChgScreen);