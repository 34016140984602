import React, { Component } from 'react';
import { Button, Container, Table } from 'reactstrap';
import AppNavbar from '../AppNavbar';
import { Link } from 'react-router-dom';
import axios from "axios";
import authHeader from '../services/auth-header';

class UserList extends Component {

    constructor(props) {
        super(props);
        this.state = {users: [], isLoading: true};
        this.remove = this.remove.bind(this);
    }

    componentDidMount() {
        this.setState({isLoading: true});

        axios.get('https://fms-api.kepczynski.pl/api/auth/users', { headers: authHeader() })
            .then(response => this.setState({users: response.data, isLoading: false}));
    }

    async remove(id) {
        await fetch(`https://fms-api.kepczynski.pl/api/v1/user/${id}`, {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(() => {
            let updatedUsers = [...this.state.users].filter(i => i.id !== id);
            this.setState({users: updatedUsers});
        });
    }

    render() {
        const {users, isLoading} = this.state;

        if (isLoading) {
            return <p className="p-5">Loading...</p>;
        }

        const userList = users.map(user => {
            return <tr key={user.id}>
                <td className="align-middle">{user.username}</td>
                <td className="align-middle">{user.userData.name}</td>
                <td className="align-middle">{user.userData.surname}</td>
                {(user.roles[0].id === 1) && <td className="align-middle">Użytkownik</td>}
                {(user.roles[0].id === 2) && <td className="align-middle">Zarządca floty</td>}
                {(user.roles[0].id === 3) && <td className="align-middle">Administrator</td>}
                <td className="align-middle">{user.email}</td>
                <td className="align-middle">
                    <Button color="primary" tag={Link} to={"/users/" + user.id}>Szczegóły</Button>
                </td>
            </tr>
        });

        return (
            <div>
                <AppNavbar/>
                <Container>
                    <div className="float-right">
                        <Button color="success" tag={Link} to="/users/new">Dodaj użytkownika</Button>{' '}
                        <Button color="secondary" onClick={() => window.location.reload(false)}>Odśwież</Button>
                    </div>
                    <h2 className={"my-5"}>Lista użytkowników</h2>
                    <Table className="mt-4">
                        <thead>
                        <tr>
                            <th width="20%">Nazwa użytkownika</th>
                            <th width="15%">Imię</th>
                            <th width="15%">Nazwisko</th>
                            <th width="20%">Rola</th>
                            <th width="20%">E-mail</th>
                            <th width="10%">Akcje</th>
                        </tr>
                        </thead>
                        <tbody>
                        {userList}
                        </tbody>
                    </Table>
                </Container>
            </div>
        );
    }
}

export default UserList;